var timeout;

import Modal from './modules/modal.js'; //quite
import objectFitImages from './modules/objectfit.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//import Swiper from './extern/swiper-min.js'; //quite
import * as Fancybox from './extern/jquery.fancybox.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	objectFitImages();
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();

	$('.quantity').each(function () {
		var spinner = $(this),
			input = spinner.find('.qty'),
			btnUp = spinner.find('.quantity-up'),
			btnDown = spinner.find('.quantity-down'),
			min = input.attr('min'),
			max = 100;

		btnUp.click(function () {
			var oldValue = parseFloat(input.val());
			if (oldValue >= max) {
				var newVal = oldValue;
			} else {
				var newVal = oldValue + 1;
			}
			spinner.find('input').val(newVal);
			spinner.find('input').trigger('change');
		});

		btnDown.click(function () {
			var oldValue = parseFloat(input.val());
			if (oldValue <= min) {
				var newVal = oldValue;
			} else {
				var newVal = oldValue - 1;
			}
			spinner.find('input').val(newVal);
			spinner.find('input').trigger('change');
		});
	});

	// Update cart
	$('.woocommerce').on('change', 'input.qty', function(){

		if ( timeout !== undefined ) {
			clearTimeout( timeout );
		}

		timeout = setTimeout(function() {
			$("[name='update_cart']").trigger("click");
		}, 1000 ); // 1 second delay, half a second (500) seems comfortable too

	});

});

if (document.querySelector('.review-slider')) {
	const productSwiper = new Swiper('.review-slider', {
		loop: true,

		// Navigation arrows
		navigation: {
			nextEl: '.button-next',
			prevEl: '.button-prev'
		}
	});
}

// const notices = document.querySelector('.notice-wrapper');

// notices &&
// 	notices.querySelector('.close').addEventListener('click', function (e) {
// 		e.currentTarget.parentNode.classList.add('hide');
// 	});
